import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { TITLES_LABELS, LABELS, ERROR_LABELS } from '../../constants';
import { IAppState } from '../../store';
import { LoginRequest, AuthState, RegisterRequest } from '../../store/auth/types';
import { loginPending, userLogout, registerPending } from '../../store/auth/actions';
import { connect } from 'react-redux';
import { isLogin, prepareCookie, getCookie, attachHeader } from '../../utils';
import { takeOnlyDigitAndPlus } from '../../utils/global';
import { emailValidator, allowOnlyAlpha, passwordValidator } from '../../utils/validators';
import Helmet from 'react-helmet';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { ForgotPasswordRequest, ForgotPasswordState } from '../../store/forgotpassword/types';
import { fetchForgotPasswordPending } from '../../store/forgotpassword/actions';
import { toast } from 'react-toastify';
import { Animated } from "react-animated-css";
import Cookie from 'js-cookie';
import apiConfigs from '../../configs/apiConfigs';
import { Spinner, Alert, Button, Modal, } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../assets/css/style.scss'
import { signInAnonymously, signInWithCustomToken, getCurrentUser } from '../../utils/firebaseUtils';
import InputMask from 'react-input-mask';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import PinInput from 'react-pin-input';
import Axios from 'axios';
import { GoogleLogin } from 'react-google-login';
import * as firebase from 'firebase/app';
import auth from 'firebase/auth';
interface Props {
    login: typeof loginPending;
    authState: AuthState;
    forgotPassword: typeof fetchForgotPasswordPending;
    forgotPasswordState: ForgotPasswordState;
    logout: typeof userLogout;
    register: typeof registerPending;
    history: any;
}
interface State {
    activeCard: number;
    emailValidation?: { valid: boolean, msg: string };
    passwordValidation?: { valid: boolean, msg: string };
    femailValidation?: { valid: boolean, msg: string };
    email?: string; password?: string; femail?: string;
    apiError?: { valid: boolean, msg: string };
    loginSuccess: boolean;
    alreadyLogin: boolean;
    isLoading: boolean;
    registerFormData: {
        email?: string;
        firstName?: string;
        lastName?: string;
        phone?: string;
        dob?: any;
        password?: string;
        cpassword?: string;
        emailValidation: { valid: boolean, msg: string };
        passwordValidation: { valid: boolean, msg: string };
        cpasswordValidation: { valid: boolean, msg: string };
        firstNameValidation: { valid: boolean, msg: string };
        lastNameValidation: { valid: boolean, msg: string };
        dobValidation: { valid: boolean, msg: string };
        phoneValidation: { valid: boolean, msg: string };
    };
    otpModal: boolean;
    verificationCodeFalse: boolean;
    loadingVerification: boolean;
    intervalTime: any;
    verificationCode: string;
    originalPatientData: any;
    userId: string;
    emailInvalid: boolean;
}
class LoginContainer extends React.Component<Props, State> {
    interval: any;
    constructor(props: any) {
        super(props);
        this.state = {
            activeCard: 0,
            loginSuccess: false,
            alreadyLogin: false,
            isLoading: false,
            otpModal: false,
            registerFormData: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                dob: moment(),
                password: '',
                cpassword: '',
                emailValidation: { valid: false, msg: '' },
                passwordValidation: { valid: false, msg: '' },
                cpasswordValidation: { valid: false, msg: '' },
                firstNameValidation: { valid: false, msg: '' },
                lastNameValidation: { valid: false, msg: '' },
                dobValidation: { valid: false, msg: '' },
                phoneValidation: { valid: false, msg: '' },
            },
            loadingVerification: false,
            verificationCodeFalse: false,
            intervalTime: '',
            verificationCode: '',
            originalPatientData: '',
            userId: '',
            emailInvalid: false
        };
        this.registerClick = this.registerClick.bind(this);
        this.checkPhoneOrEmail = this.checkPhoneOrEmail.bind(this);
    }
    onClickLogin() {
        if (!this.state.email || this.state.email === "") {
            this.setState({ emailValidation: { valid: false, msg: ERROR_LABELS.REQUIRED.EMAIL } });
            return;
        } else if (!this.state.password || this.state.password === "") {
            this.setState({ passwordValidation: { valid: false, msg: ERROR_LABELS.REQUIRED.PASSWORD } });
            return;
        } else if (!emailValidator(this.state.email)) {
            this.setState({ emailValidation: { valid: false, msg: 'Invalid email' } });
            return;
        }
        this.setState({ emailValidation: { msg: '', valid: false }, passwordValidation: { msg: '', valid: false }, isLoading: true });
        this.props.login({ email: this.state.email, password: this.state.password });
    }
    onClickForgotPassword() {
        console.log(this.state.femail);
        if (!this.state.femail || this.state.femail === "") {
            this.setState({ femailValidation: { valid: false, msg: ERROR_LABELS.REQUIRED.EMAIL } });
            return;
        } else if (!emailValidator(this.state.femail)) {
            this.setState({ femailValidation: { valid: false, msg: 'Invalid email' } });
            return;
        }
        this.setState({ femailValidation: { msg: '', valid: false }, isLoading: true });
        this.props.forgotPassword({ email: this.state.femail, modelType: 'employee' });
    }
    componentDidMount() {
        if (this.props.authState.loginResponse && this.props.authState.loginResponse.status) {
            this.props.authState.loginResponse.status.error = false;
        }
        if (isLogin()) {
            // this.setState({ isLoading: true });
            /* if (!getCurrentUser()) {
                try {
                    signInWithCustomToken(getCookie().jwtToken).then((signInSuccess) => {
                        this.setState({ alreadyLogin: true, isLoading: false });
                    }).catch((error) => {
                        this.setState({ isLoading: false });
                        this.props.logout();
                    })
                } catch (err) {
                    this.setState({ isLoading: false });
                    this.props.logout();
                }
            } else { */
            this.setState({ alreadyLogin: true, isLoading: false });
            /* } */
            /*  signInAnonymously(); */
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
            if (nextProps.authState.error !== null) {
                if (nextProps.authState.error.status && nextProps.authState.error.status.error === true) {
                    console.log(nextProps, nextProps.authState.error.status.error);
                    this.setState({ apiError: { valid: false, msg: nextProps.authState.error.status.msg }, isLoading: false });
                } else {
                    this.setState({ apiError: { valid: false, msg: 'Something went wrong' }, isLoading: false });
                }
            } else {
                if (nextProps.authState.pending === false) {
                    if (nextProps.authState.loginResponse &&
                        nextProps.authState.loginResponse !== this.props.authState.loginResponse &&
                        nextProps.authState.pending === false &&
                        nextProps.authState.loginResponse.status &&
                        nextProps.authState.loginResponse.status.error === false) {
                        /*  signInWithCustomToken(nextProps.authState.loginResponse.jwtToken).then((success) => { */
                        if (nextProps.authState.loginResponse) {
                            delete nextProps.authState.loginResponse.status;
                            const key1 = 'xprUser.employee-' + nextProps.authState.loginResponse.id;
                            const key2 = 'xpr_user_id';
                            const key3 = 'xprAuthUser';
                            const key4 = 'xprUserName';
                            const key5 = 'jwtToken';
                            var json: any = {};
                            json[key1] = true;
                            json[key2] = nextProps.authState.loginResponse.uniqueId;
                            json[key3] = nextProps.authState.loginResponse.authParam;
                            json[key4] = nextProps.authState.loginResponse.firstName + " " + nextProps.authState.loginResponse.lastName;
                            json[key5] = nextProps.authState.loginResponse.jwtToken;
                            Cookie.set('employee_cookie', json, { expires: 30, domain: apiConfigs.COOKIE_DOMAIN });
                            Cookie.set('employee_cookie', json, { expires: 30 });
                            this.setState({ loginSuccess: true, isLoading: false });
                        } else {
                            this.setState({ alreadyLogin: true });
                        }
                        /* }).catch((error) => {
                            this.setState({ alreadyLogin: true });
                        }) */
                    } else if (nextProps.authState.registerResponse &&
                        nextProps.authState.registerResponse !== this.props.authState.registerResponse &&
                        nextProps.authState.pending === false) {
                        this.setState({
                            otpModal: true, verificationCodeFalse: false,
                            intervalTime: 30,
                            userId: nextProps.authState.registerResponse.id
                        });
                        this.interval = setInterval(() => {
                            if (this.state.intervalTime === 0) {
                                clearInterval(this.interval);
                            } else {
                                this.setState({ intervalTime: this.state.intervalTime - 1 });
                            }
                        }, 1000)
                    }
                }
            }
            if (this.props.forgotPasswordState !== nextProps.forgotPasswordState) {
                console.log(nextProps.forgotPasswordState.error);
                if (nextProps.forgotPasswordState.error !== null) {
                    this.setState({ isLoading: false });
                    if (nextProps.forgotPasswordState.error.status && nextProps.forgotPasswordState.error.status.error === true) {
                        this.setState({
                            femailValidation: {
                                valid: false,
                                msg: nextProps.forgotPasswordState.error.status.msg
                            }
                        });
                    } else {
                        this.setState({
                            femailValidation: {
                                valid: false,
                                msg: 'Something went wrong'
                            }
                        });
                    }
                } else {
                    if (nextProps.forgotPasswordState.pending === false) {
                        if (nextProps.forgotPasswordState.forgotPasswordResponse && nextProps.forgotPasswordState.forgotPasswordResponse.status &&
                            nextProps.forgotPasswordState.forgotPasswordResponse.status.error === false) {
                            this.setState({ femail: '', isLoading: false });
                            this.setState({ femailValidation: { valid: false, msg: nextProps.forgotPasswordState.forgotPasswordResponse.status.msg } });
                        }
                    }
                }
            }
        }
        return true;
    }
    componentDidUpdate() {
        this.props.authState.error = null;
        this.props.forgotPasswordState.error = null;
        if (this.props.authState.loginResponse && this.props.authState.loginResponse.status) {
            this.props.authState.loginResponse.status.error = true;
        }
        if (this.props.forgotPasswordState.forgotPasswordResponse && this.props.forgotPasswordState.forgotPasswordResponse.status) {
            this.props.forgotPasswordState.forgotPasswordResponse.status.error = true;
        }
    }
    registerClick() {
        if (this.state.registerFormData.firstName === "") {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: true, msg: 'First Name required' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (this.state.registerFormData.lastName === "") {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: true, msg: 'Last name required' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (this.state.registerFormData.email === "" || (this.state.registerFormData.email && !emailValidator(this.state.registerFormData.email))) {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: true, msg: 'Email required' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (this.state.registerFormData.phone === "" || this.state.registerFormData.phone?.length !== 16) {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: true, msg: 'Invalid Phone' },
                }
            });
            return;
        } else if (this.state.registerFormData.dob === "") {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: true, msg: 'Date of birth required' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (this.state.registerFormData.dob && moment().diff(this.state.registerFormData.dob, "years") < 18) {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: true, msg: 'Should be greater than 18 years' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (this.state.registerFormData.password === "") {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: true, msg: 'Password required' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (this.state.registerFormData.cpassword === "") {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: true, msg: 'Confirm Password Required' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (!passwordValidator(this.state.registerFormData.password ? this.state.registerFormData.password : '')) {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: true, msg: 'Password must be at least 6 characters long(contain a number and an uppercase and lowercase).' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (!passwordValidator(this.state.registerFormData.cpassword ? this.state.registerFormData.cpassword : '')) {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    passwordValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: true, msg: 'Confirm Password must be at least 6 characters long(contain a number and an uppercase and lowercase).' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        } else if (this.state.registerFormData.password && this.state.registerFormData.cpassword && this.state.registerFormData.password !== this.state.registerFormData.cpassword) {
            this.setState({
                registerFormData: {
                    ...this.state.registerFormData,
                    firstNameValidation: { valid: false, msg: '' },
                    lastNameValidation: { valid: false, msg: '' },
                    emailValidation: { valid: false, msg: '' },
                    cpasswordValidation: { valid: true, msg: 'Confirm Password shoule be same as password' },
                    passwordValidation: { valid: false, msg: '' },
                    dobValidation: { valid: false, msg: '' },
                    phoneValidation: { valid: false, msg: '' },
                }
            });
            return;
        }
        this.setState({
            registerFormData: {
                ...this.state.registerFormData,
                firstNameValidation: { valid: false, msg: '' },
                lastNameValidation: { valid: false, msg: '' },
                emailValidation: { valid: false, msg: '' },
                cpasswordValidation: { valid: false, msg: '' },
                passwordValidation: { valid: false, msg: '' },
                dobValidation: { valid: false, msg: '' },
                phoneValidation: { valid: false, msg: '' },
            }
        });
        if (this.state.registerFormData.dob &&
            this.state.registerFormData.password &&
            this.state.registerFormData.email && this.state.registerFormData.firstName &&
            this.state.registerFormData.lastName && this.state.registerFormData.lastName &&
            this.state.registerFormData.phone) {
            this.props.register({
                dob: moment(this.state.registerFormData.dob).format("YYYY-DD-MM"),
                email: this.state.registerFormData.email,
                fname: this.state.registerFormData.firstName,
                lname: this.state.registerFormData.lastName,
                phone: takeOnlyDigitAndPlus(this.state.registerFormData.phone),
                registeras: "normal",
                password: this.state.registerFormData.password
            });
        }

    }
    responseGoogle(response: any) {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        firebase.auth().signOut().then((success) => {
            console.log('Signout', success);
        }).catch((error) => {
            console.log('Signout:Error', error);
        });
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithPopup(provider).then((result: any) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            // ...
            this.setState({ isLoading: true });
            Axios.post(apiConfigs.API_URL + 'signup/', {
                email: user.email,
                loginas: "google",
                loginFron: "web"
            }).then((success: any) => {
                if (success.status !== 200 || (success.data && success.data.status.error === true)) {
                    toast.error(success.data.status.msg ? success.data.status.msg : 'Login error');
                    this.setState({ isLoading: false });
                } else {
                    if (success.data.isNewPatient) {
                        toast.warn('Please fill out form to register');
                        this.setState({
                            activeCard: 2, registerFormData: {
                                ...this.state.registerFormData,
                                firstName: user.displayName.split(" ")[0] ? user.displayName.split(" ")[0] : '',
                                lastName: user.displayName.split(" ")[1] ? user.displayName.split(" ")[1] : '',
                                email: user.email
                            },
                            isLoading: false
                        });
                    } else {
                        /* signInWithCustomToken(success.data.jwtToken).then((customResponse) => { */
                        if (success.data) {
                            delete success.data.status;
                            const key1 = 'xprUser.employee-' + success.data.id;
                            const key2 = 'xpr_user_id';
                            const key3 = 'xprAuthUser';
                            const key4 = 'xprUserName';
                            const key5 = 'jwtToken';
                            var json: any = {};
                            json[key1] = true;
                            json[key2] = success.data.uniqueId;
                            json[key3] = success.data.authParam;
                            json[key4] = success.data.firstName + " " + success.data.lastName;
                            json[key5] = success.data.jwtToken;
                            Cookie.set('employee_cookie', json, { expires: 30, domain: apiConfigs.COOKIE_DOMAIN });
                            Cookie.set('employee_cookie', json, { expires: 30 });
                            this.setState({ loginSuccess: true, isLoading: false });
                        } else {
                            this.setState({ alreadyLogin: true, isLoading: false });
                        }
                        /* }).catch((error) => {
                            this.setState({ alreadyLogin: true, isLoading: false });
                        }) */
                    }
                }
            }).catch((error) => {
                this.setState({ isLoading: false });
                toast.error('Login error');
            });
            console.log(user, token);
        }).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
            toast.error(error.message);
            this.setState({ isLoading: false });
        });
    }
    render() {
        if (this.state.loginSuccess === true || this.state.alreadyLogin === true) {
            return (
                <Redirect to="/"></Redirect>
            )
        } else {
            return (
                <div className="master_patientp">

                    <header className="inner-header">
                        <div className="container">
                            <NavLink className="navbar-brand" to="/login">
                                <img src={require('../../assets/images/logo-black.svg')} alt="logo" />
                            </NavLink>
                        </div>
                    </header>

                    <section className="login-page">
                        <Helmet><title>{TITLES_LABELS.META.LOGIN}</title></Helmet>
                        <div className="container h-100">
                            <div className="row justify-content-center h-100">

                                {
                                    this.state.activeCard === 0 &&
                                    <>
                                        <div className="col-md-4 align-self-center">
                                            <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                                <div className="login-sec">
                                                    <div className="login-title">
                                                        {TITLES_LABELS.LOGIN.PATIENT_LOGIN}
                                                    </div>
                                                    <div className="login-form">
                                                        {
                                                            (this.state.apiError && this.state.apiError.valid === false) &&
                                                            <Alert variant="danger">
                                                                {this.state.apiError.msg}
                                                            </Alert>
                                                        }
                                                        <div className="form-group">
                                                            <label>{LABELS.EMAIL}</label>
                                                            <input type="email" value={this.state.email ? this.state.email : ''}
                                                                onInput={(e: any) => { this.setState({ email: e.target.value, emailValidation: { msg: '', valid: false } }) }}
                                                                onChange={(e) => { this.setState({ email: e.target.value, emailValidation: { msg: '', valid: false } }) }} className="form-control" />
                                                            {
                                                                (this.state.emailValidation && !this.state.emailValidation.valid) &&
                                                                <div className="text-danger error">{this.state.emailValidation.msg}</div>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>{LABELS.PASSWORD}</label>
                                                            <input type="password" value={this.state.password ? this.state.password : ''}
                                                                onInput={(e: any) => { this.setState({ password: e.target.value, passwordValidation: { msg: '', valid: false } }) }}
                                                                onChange={(e) => { this.setState({ password: e.target.value, passwordValidation: { msg: '', valid: false } }) }} className="form-control" />
                                                            {
                                                                (this.state.passwordValidation && !this.state.passwordValidation.valid) &&
                                                                <div className="text-danger error">{this.state.passwordValidation.msg}</div>
                                                            }
                                                        </div>
                                                        <button className="btn btn-custom w-100" onClick={(e) => { this.onClickLogin() }}>{LABELS.BUTTON.LOGIN}
                                                            <span className="aft_load right">
                                                                <>
                                                                    {
                                                                        this.state.isLoading === true &&
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    }
                                                                    {
                                                                        this.state.isLoading === false &&
                                                                        <img src={require('../../assets/images/ic_arrow_upward_24px.svg')} alt="" />
                                                                    }
                                                                </>
                                                            </span>
                                                        </button>

                                                        <hr />
                                                        {/* <a href="#" className="btn btn-secondary text-center w-100" onClick={this.responseGoogle.bind(this)}><i className="fab fa-google mr-2"></i> Login with google</a> */}
                                                        <div className="fprelink d-flex justify-content-between">
                                                            <a href="javascript:void(0)" onClick={(e) => { this.setState({ activeCard: 1 }) }}>{TITLES_LABELS.LINKS.FORGOT_PASSWORD}</a>
                                                            {/* <a href="javascript:void(0)" onClick={(e) => { this.setState({ activeCard: 2 }) }}>{TITLES_LABELS.LINKS.REGISTER}</a> */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </Animated>
                                        </div>
                                    </>
                                }
                                {
                                    this.state.activeCard === 1 &&
                                    <div className="col-md-4 align-self-center">
                                        <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                            <div className="login-sec">
                                                <div className="login-title">
                                                    {TITLES_LABELS.LOGIN.FORGOT_PASSWORD}
                                                    <p>{LABELS.FORGOT_PASSWORD_DESC}</p>
                                                </div>
                                                <div className="login-form">
                                                    <div className="form-group">
                                                        <label>{LABELS.EMAIL}</label>
                                                        <input type="email" value={this.state.femail ? this.state.femail : ''}
                                                            onInput={(e: any) => { this.setState({ femail: e.target.value }) }}
                                                            onChange={(e) => { this.setState({ femail: e.target.value }) }} className="form-control" />

                                                        {
                                                            (this.state.femailValidation && !this.state.femailValidation.valid) &&
                                                            <div className="text-danger error">{this.state.femailValidation.msg}</div>
                                                        }

                                                    </div>

                                                    <button className="btn btn-custom w-100" onClick={(e) => { this.onClickForgotPassword() }}>
                                                        {LABELS.BUTTON.SEND_RESET_LINK}
                                                        <span className="aft_load right">
                                                            <>
                                                                {
                                                                    this.state.isLoading === true &&
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                }
                                                                {
                                                                    this.state.isLoading === false &&
                                                                    <img src={require('../../assets/images/ic_arrow_upward_24px.svg')} alt="" />
                                                                }
                                                            </>
                                                        </span>
                                                    </button>
                                                    <div className="fprelink text-right">
                                                        <a href="javascript:void(0)"
                                                            onClick={(e) => { this.setState({ activeCard: 0, femailValidation: { msg: '', valid: false } }) }}>back to login
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Animated>
                                    </div>
                                }
                                {
                                    this.state.activeCard === 2 &&
                                    <div className="col-md-6 align-self-center">
                                        <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                            <div className="login-sec register">
                                                <div className="login-title">
                                                    {TITLES_LABELS.LOGIN.REGISTER}
                                                    <p>{LABELS.REGISTER}</p>
                                                </div>
                                                <div className="login-form">
                                                    {
                                                        (this.state.apiError && this.state.apiError.valid === false) &&
                                                        <Alert variant="danger">
                                                            {this.state.apiError.msg}
                                                        </Alert>
                                                    }
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>First Name</label>
                                                                <input type="text" name="firstName" className="form-control"
                                                                    onInput={(e: any) => {
                                                                        if (allowOnlyAlpha(e.target.value)) {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    firstName: e.target.value
                                                                                }
                                                                            });
                                                                        } else if (e.target.value === "") {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    firstName: e.target.value
                                                                                }
                                                                            });
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        if (allowOnlyAlpha(e.target.value)) {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    firstName: e.target.value
                                                                                }
                                                                            });
                                                                        } else if (e.target.value === "") {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    firstName: e.target.value
                                                                                }
                                                                            });
                                                                        }
                                                                    }} value={this.state.registerFormData.firstName} />
                                                                {
                                                                    (this.state.registerFormData.firstNameValidation && this.state.registerFormData.firstNameValidation.valid) &&
                                                                    <div className="text-danger error">{this.state.registerFormData.firstNameValidation.msg}</div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Last Name</label>
                                                                <input type="text" name="lastName" className="form-control"
                                                                    onInput={(e: any) => {
                                                                        if (allowOnlyAlpha(e.target.value)) {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    lastName: e.target.value
                                                                                }
                                                                            });
                                                                        } else if (e.target.value === "") {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    lastName: e.target.value
                                                                                }
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    lastName: ''
                                                                                }
                                                                            });
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        if (allowOnlyAlpha(e.target.value)) {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    lastName: e.target.value
                                                                                }
                                                                            });
                                                                        } else if (e.target.value === "") {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    lastName: e.target.value
                                                                                }
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                registerFormData: {
                                                                                    ...this.state.registerFormData,
                                                                                    lastName: ''
                                                                                }
                                                                            });
                                                                        }
                                                                    }}
                                                                    value={this.state.registerFormData.lastName} />
                                                                {
                                                                    (this.state.registerFormData.lastNameValidation && this.state.registerFormData.lastNameValidation.valid) &&
                                                                    <div className="text-danger error">{this.state.registerFormData.lastNameValidation.msg}</div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <label>Email</label>
                                                                <input type="text" name="email" className="form-control"
                                                                    onBlur={(e) => {
                                                                        if (!(e.target.value === "" || !emailValidator(e.target.value))) {
                                                                            this.checkPhoneOrEmail(e.target.value, "email");
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            registerFormData: {
                                                                                ...this.state.registerFormData,
                                                                                email: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    value={this.state.registerFormData.email} />
                                                                {
                                                                    (this.state.registerFormData.emailValidation && this.state.registerFormData.emailValidation.valid) &&
                                                                    <div className="text-danger error">{this.state.registerFormData.emailValidation.msg}</div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Phone</label>
                                                                <InputMask mask="+1(999) 999-9999" name="phone" value={this.state.registerFormData.phone} onInput={(e: any) => {
                                                                    this.setState({
                                                                        registerFormData: {
                                                                            ...this.state.registerFormData,
                                                                            phone: e.target.value
                                                                        }
                                                                    });
                                                                }}
                                                                    onChange={(e: any) => {
                                                                        this.setState({
                                                                            registerFormData: {
                                                                                ...this.state.registerFormData,
                                                                                phone: e.target.value
                                                                            }
                                                                        });
                                                                    }} >
                                                                    {(inputProps: any) => <input {...inputProps} className="form-control"></input>}
                                                                </InputMask>
                                                                {
                                                                    (this.state.registerFormData.phoneValidation && this.state.registerFormData.phoneValidation.valid) &&
                                                                    <div className="text-danger error">{this.state.registerFormData.phoneValidation.msg}</div>
                                                                }
                                                                {/* <input type="text" className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            registerFormData: {
                                                                                ...this.state.registerFormData,
                                                                                phone: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    value={this.state.registerFormData.phone} /> */}
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Date of Birth</label>
                                                                <KeyboardDatePicker
                                                                    id="date-picker-dialog"
                                                                    format="YYYY/MM/DD"
                                                                    name="dob"
                                                                    value={this.state.registerFormData.dob}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                                {
                                                                    (this.state.registerFormData.dobValidation && this.state.registerFormData.dobValidation.valid) &&
                                                                    <div className="text-danger error">{this.state.registerFormData.dobValidation.msg}</div>
                                                                }
                                                                {/* <input type="text" className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            registerFormData: {
                                                                                ...this.state.registerFormData,
                                                                                dob: e.target.value
                                                                            }
                                                                        });
                                                                    }}
                                                                    value={this.state.registerFormData.dob} /> */}
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Password</label>
                                                                <input type="password" name="password" className="form-control" onChange={(e) => {
                                                                    this.setState({
                                                                        registerFormData: {
                                                                            ...this.state.registerFormData,
                                                                            password: e.target.value
                                                                        }
                                                                    });
                                                                }} value={this.state.registerFormData.password} />
                                                                {
                                                                    (this.state.registerFormData.passwordValidation && this.state.registerFormData.passwordValidation.valid) &&
                                                                    <div className="text-danger error">{this.state.registerFormData.passwordValidation.msg}</div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Re Type Password</label>
                                                                <input type="password" name="cpassword" onChange={(e) => {
                                                                    this.setState({
                                                                        registerFormData: {
                                                                            ...this.state.registerFormData,
                                                                            cpassword: e.target.value
                                                                        }
                                                                    });
                                                                }} value={this.state.registerFormData.cpassword} className="form-control" />
                                                                {
                                                                    (this.state.registerFormData.cpasswordValidation && this.state.registerFormData.cpasswordValidation.valid) &&
                                                                    <div className="text-danger error">{this.state.registerFormData.cpasswordValidation.msg}</div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12">
                                                            <button className="btn btn-custom w-100"
                                                                disabled={this.props.authState.pending || this.state.emailInvalid || this.state.isLoading}
                                                                onClick={(e) => {
                                                                    this.registerClick()
                                                                }}>Register
                                                                <span className="aft_load right">
                                                                    <>
                                                                        {
                                                                            this.props.authState.pending === true || this.state.isLoading &&
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />
                                                                        }
                                                                        {
                                                                            this.props.authState.pending === false &&
                                                                            <img src={require('../../assets/images/ic_arrow_upward_24px.svg')} alt="" />
                                                                        }
                                                                    </>
                                                                </span>
                                                            </button>
                                                        </div>


                                                    </div>

                                                    <div className="fprelink text-right">
                                                        <a href="javascript:void(0)"
                                                            onClick={(e) => { this.setState({ activeCard: 0, femailValidation: { msg: '', valid: false } }) }}>back to login
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Animated>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                    {
                        this.state.otpModal &&
                        <Modal centered className="phoneverify" show={this.state.otpModal} onHide={() => {
                            this.setState({ otpModal: false, verificationCodeFalse: false, intervalTime: 30 });
                            clearInterval(this.interval);
                        }}>
                            <div className="popup-sec">
                                <div className="popup-title text-center">
                                    Verify your number
                            </div>
                                <div className="popup-form text-center">
                                    <p>We have sent a verification code to <span>{this.state.originalPatientData ? this.state.originalPatientData.phone : ''}</span></p>
                                    <div className="form-txt">Enter code here</div>
                                    <div className="form-group">
                                        <PinInput
                                            length={4}
                                            initialValue={""}
                                            onChange={(value, index) => {
                                                this.setState({ verificationCode: value });
                                            }}
                                            type="numeric"
                                            style={{ padding: '10px' }}
                                            inputStyle={{}}
                                            inputFocusStyle={{ borderColor: 'blue' }}
                                            onComplete={(value, index) => {
                                                this.setState({ verificationCode: value });
                                            }}
                                        />
                                        {
                                            this.state.verificationCodeFalse &&
                                            <div className="text-danger error">Verification code entered is incorrect.</div>
                                        }
                                    </div>
                                    <button className="btn btn-custom send-btn"
                                        onClick={(e) => { this.verifyCode() }}
                                        disabled={this.state.verificationCode.length !== 4 || this.state.loadingVerification}>
                                        <img src={require('../../assets/images/ic_arrow_upward_24px.svg')} />
                                    </button>
                                    {
                                        this.state.intervalTime !== 0 &&
                                        <div className="resend-txt">Didn't received code? Resend after {this.state.intervalTime} seconds.</div>
                                    }
                                    {
                                        this.state.intervalTime === 0 &&
                                        <button className="btn resend-btn" onClick={(e) => { this.sendVerificationCode() }}>
                                            Resend Code
                                            {
                                                this.state.loadingVerification &&
                                                <span className="aft_load right">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            }
                                        </button>
                                    }
                                </div>
                            </div>
                        </Modal>
                    }
                </div>
            )
        }
    }
    handleChange(date: any) {
        this.setState({
            registerFormData: {
                ...this.state.registerFormData,
                dob: date
            }
        });
    };
    checkPhoneOrEmail(emailOrPhone: string, type: string) {
        let obj = {};
        if (type === "email") {
            obj = {
                type: "email",
                email: emailOrPhone
            }
        }
        this.setState({ isLoading: true });
        Axios.post(apiConfigs.API_URL + 'signup/validate/', obj).then((success) => {
            this.setState({ loadingVerification: false, isLoading: false });
            if (success.status === 200 && success.data !== "") {
                if (success.data.status.error === true) {
                    toast.warn(success.data.status.msg);
                    this.setState({ emailInvalid: true });
                } else {
                    this.setState({ emailInvalid: false });
                }
            }
        }, (error) => {
            this.setState({ loadingVerification: false, isLoading: false });
            toast.error('Something went wrong');
        });
    }
    sendVerificationCode() {
        if (this.state.registerFormData && this.state.userId !== "") {
            this.setState({ loadingVerification: true });
            Axios.post(apiConfigs.API_URL + 'signup/' + this.state.userId + '/resend/', {
                phone: this.state.registerFormData.phone
            }).then((success) => {
                this.setState({ loadingVerification: false });
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast.success(success.data.status.msg);
                        this.setState({ otpModal: true, verificationCodeFalse: false });
                        this.interval = setInterval(() => {
                            if (this.state.intervalTime === 0) {
                                clearInterval(this.interval);
                            } else {
                                this.setState({ intervalTime: this.state.intervalTime - 1 });
                            }
                        }, 1000)
                    } else {
                        toast.error(success.data.status.msg);
                    }
                }
            }, (error) => {
                this.setState({ loadingVerification: false });
                toast.error('Something went wrong');
            });
        }
    }
    verifyCode() {
        if (this.state.registerFormData && this.state.userId !== "") {
            this.setState({ loadingVerification: true });
            Axios.post(apiConfigs.API_URL + 'signup/' + this.state.userId + '/', {
                phone: this.state.registerFormData.phone,
                code: this.state.verificationCode
            }).then((success: any) => {
                this.setState({ loadingVerification: false });
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast(success.data.status.msg);
                        console.log(success.data);
                        this.setState({ otpModal: false, verificationCodeFalse: false });
                        /* signInWithCustomToken(success.data.jwtToken).then((response) => {
                            if (response) { */
                        const key1 = 'xprUser.employee-' + success.data.id;
                        const key2 = 'xpr_user_id';
                        const key3 = 'xprAuthUser';
                        const key4 = 'xprUserName';
                        const key5 = 'jwtToken';
                        var json: any = {};
                        json[key1] = true;
                        json[key2] = success.data.uniqueId;
                        json[key3] = success.data.authParam;
                        json[key4] = success.data.firstName + " " + success.data.lastName;
                        json[key5] = success.data.jwtToken;
                        Cookie.set('employee_cookie', json, { expires: 30, domain: apiConfigs.COOKIE_DOMAIN });
                        Cookie.set('employee_cookie', json, { expires: 30 });
                        this.setState({ loginSuccess: true, isLoading: false });
                        /* } else {
                            toast.error('Something went wrong');
                        }
                    }).catch((error) => {
                        toast.error('Something went wrong');
                    }); */
                    } else {
                        this.setState({ verificationCodeFalse: true });
                    }
                }
            }, (error) => {
                this.setState({ loadingVerification: false });
                toast.error('Something went wrong');
            });
        }
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    login: (login: LoginRequest) => {
        dispatch(loginPending(login));
    },
    logout: () => {
        dispatch(userLogout())
    },
    register: (request: RegisterRequest) => {
        dispatch(registerPending(request));
    },
    forgotPassword: (forgotPassword: ForgotPasswordRequest) => {
        dispatch(fetchForgotPasswordPending(forgotPassword));
    }
});
const mapStateToProps = (state: IAppState) => ({
    authState: state.auth,
    forgotPasswordState: state.forgotPassword
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);