export enum EMPLOYEE_DATA {
    EMPLOYEE_DATA_LOAD_PENDING = 'EMPLOYEE_DATA_LOAD_PENDING',
    EMPLOYEE_DATA_LOAD_ERROR = 'EMPLOYEE_DATA_LOAD_ERROR',
    EMPLOYEE_DATA_LOAD_SUCCESS = 'EMPLOYEE_DATA_LOAD_SUCCESS',
    EMPLOYEE_NAME_UPDATED = 'EMPLOYEE_NAME_UPDATED',
    EMAIL_VERIFICATION_ERROR = 'EMAIL_VERIFICATION_ERROR',
    EMAIL_VERIFICATION_PENDING = 'EMAIL_VERIFICATION_PENDING',
    EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS',
    SUBMIT_PRESCREENING = 'SUBMIT_PRESCREENING',
    SUBMIT_PRESCREENING_ERROR = 'SUBMIT_PRESCREENING_ERROR',
    SUBMIT_PRESCREENING_SUCCESS = 'SUBMIT_PRESCREENING_SUCCESS',
};
export interface EmployeeDataRequest {
    userId?: string;
    uniqueId: string;
    doNotShowLoader?: boolean;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface Appointment {
    id: string;
    uniqueid: string;
    currentStatus: string;
    requests: { isNewEmployee: boolean };
    billingdata: any;
    date: any;
    time: any;
    claimStus: any;
    claimStatus: any;
    clinic: {
        id: string;
        email: string;
        phone: string;
        uniqueid: string;
        name: string;
        metadata: {
            acceptAssignment: boolean;
            googleReviewUrl: string;
            isTaxable: boolean;
            reviewOn: string[];
            trustApiKey: string;
        }
    };
    doctor: {
        fullname: string;
        id: string;
        email: string;
        uniqueid: string;
        gender: string;
        photo: { name: string, type: string };
    };
    metadata: {
        referral: string;
        patient: {
            reminder: number;
            isNewEmployee: boolean;
        },
        appointment: {
            cancellation: {
                reason: any;
                allowRescheduling: any;
            },
            prescreening: any;
            checkin: any;
            payment: any;
        }
    };
    stripeAccount: { customerId: string, accountID: string };
    rating: any;
    reviewText: any;
}
export interface Clinic {
    id: string;
    address: any;
    logo: {
        name: string;
        type: string;
    };
    name: string;
    uniqueId: string;
    prescreen: boolean;
    workid: string;
    email: string;
};
export interface EmployeeDataResponse {
    id: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: string;
    gender: string;
    dob: string;
    phone: string;
    phoneVerified: string;
    referralCode: string;
    addressline1: string;
    addressline2: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
    referral: number;
    appointments: Appointment[];
    status: Status;
    defaultClaim: string;
    prescreening: any;
    clinics: Clinic[];
}

export interface AccountUpdateRequest {
    userId?: string;
    uniqueId: string;
    updateType: string;
    firstName?: string;
    lastName?: string;
    gender?: string;
    email?: string;
    phone?: string;
    dob?: string;
    address?: {
        addressline1: string; addressline2: string; country: string; state: string; city: string;
        postalCode: string;
    },
    password?: { cpassword: string; nPassword: string; rpassword: string }
}

export interface AccountUpdateResponse {
    status: Status;
    updateType: string;
    firstName?: string;
    lastName?: string;
    gender?: string;
    email?: string;
    phone?: string;
    dob?: string;
    address?: {
        addressline1: string; addressline2: string; country: string; state: string; city: string;
        postalCode: string;
    }
}

export interface EmployeeReferRequest {
    // userId: string;
    uniqueId: string;
    inviteEmail: string;
}
export interface EmployeeReferResponse {
    status: Status;
}

export interface EmailVerificationRequest {
    modelType: string;
    modelUniqueid: string;
    email: string;
}
export interface EmailVerificationResponse {
    status: Status;
}
export interface RemoveDefaultClinicRequest {
    clinicId: string;
}
export interface RemoveDefaultClinicResponse {
    status: Status;
}
export interface AddDefaultClinicRequest {
    clinicId: string;
    defaultClinicObj: any;
}
export interface AddDefaultClinicResponse {
    status: Status;
}
export interface ChangeWriteReview {
    score: string;
    comment: string;
    appointmentUniqueId: string;
}
export interface PrescreeningSubmitRequest {
    employeeUniqueId: string;
    workid: string;
    data: any;
}
export interface PrescreeningSubmitResponse {
    status: Status;
}
export interface EmployeeDataState {
    pending: boolean;
    employeeDataRequest?: EmployeeDataRequest;
    employeeDataResponse?: EmployeeDataResponse;
    accountUpdateRequest?: AccountUpdateRequest;
    accountUpdateResponse?: AccountUpdateResponse;
    employeeReferRequest?: EmployeeReferRequest;
    employeeReferResponse?: EmployeeReferResponse;
    emailVerificationRequest?: EmailVerificationRequest;
    emailVerificationResponse?: EmailVerificationResponse;
    removeDefaultClinicRequest?: RemoveDefaultClinicRequest;
    removeDefaultClinicResponse?: RemoveDefaultClinicResponse;
    addDefaultClinicRequest?: AddDefaultClinicRequest;
    addDefaultClinicResponse?: AddDefaultClinicResponse;
    changeWriteReview?: ChangeWriteReview;
    prescreeningSubmitRequest?: PrescreeningSubmitRequest;
    prescreeningSubmitResponse?: PrescreeningSubmitResponse;
    error: any;
}

export interface EmployeeDataPendingAction {
    type: EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_PENDING;
    payload: EmployeeDataRequest;
}

export interface EmployeeDataErrorAction {
    type: EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_ERROR;
    error: any;
}
export interface EmployeeDataSuccessAction {
    type: EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_SUCCESS;
    employeeData: EmployeeDataResponse;
}

/* Account */

export enum ACCOUNT {
    ACCOUNT_UPDATE_PENDING = 'ACCOUNT_UPDATE_PENDING',
    ACCOUNT_UPDATE_ERROR = 'ACCOUNT_UPDATE_ERROR',
    ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS'
};

export interface AccountUpdatePendingAction {
    type: ACCOUNT.ACCOUNT_UPDATE_PENDING;
    payload: AccountUpdateRequest;
}

export interface AccountUpdateErrorAction {
    type: ACCOUNT.ACCOUNT_UPDATE_ERROR;
    error: any;
}
export interface AccountUpdateSuccessAction {
    type: ACCOUNT.ACCOUNT_UPDATE_SUCCESS;
    accountUpdate: AccountUpdateResponse;
}

export interface EmailVerificationPendingAction {
    type: EMPLOYEE_DATA.EMAIL_VERIFICATION_PENDING;
    payload: EmailVerificationRequest;
}
export interface EmailVerificationSuccessAction {
    type: EMPLOYEE_DATA.EMAIL_VERIFICATION_SUCCESS;
    emailVerification: EmailVerificationResponse;
}
export interface EmailVerificationErrorAction {
    type: EMPLOYEE_DATA.EMAIL_VERIFICATION_ERROR;
    error: any;
}
/* Prescreening Submit */
export interface PrescreeningSubmitPendingAction {
    type: EMPLOYEE_DATA.SUBMIT_PRESCREENING;
    payload: PrescreeningSubmitRequest;
}
export interface PrescreeningSubmitErrorAction {
    type: EMPLOYEE_DATA.SUBMIT_PRESCREENING_ERROR;
    payload: any;
}
export interface PrescreeningSubmitSuccessAction {
    type: EMPLOYEE_DATA.SUBMIT_PRESCREENING_SUCCESS;
    payload: PrescreeningSubmitResponse;
}
export type EmployeeDataActionTypes = EmployeeDataPendingAction | EmployeeDataErrorAction | EmployeeDataSuccessAction |
    AccountUpdateErrorAction | AccountUpdatePendingAction | AccountUpdateSuccessAction |
    EmailVerificationPendingAction | EmailVerificationSuccessAction | EmailVerificationErrorAction |
    PrescreeningSubmitPendingAction | PrescreeningSubmitErrorAction | PrescreeningSubmitSuccessAction;