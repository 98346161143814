import React from 'react';
import './MyClinic.scss';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { EmployeeDataState, Clinic } from '../../store/employeeData/types';
import configs from '../../configs/apiConfigs';
import { Avatar } from '@material-ui/core';
import { showAddress } from '../renderAddress';
import { toast } from 'react-toastify';
import PrescreeningModal from './PrescreeningModal';
interface Props {
    employeeDataState: EmployeeDataState;
}
interface State {
    clinic: Clinic[];
    showPrescreeningModal: boolean;
    selectedClinic?: Clinic;
}
class MyClinic extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            clinic: [],
            showPrescreeningModal: false
        };
    }
    componentDidMount() {
        if (this.props.employeeDataState && this.props.employeeDataState.employeeDataResponse && this.props.employeeDataState.employeeDataResponse.clinics.length > 0) {
            this.setState({
                clinic: this.props.employeeDataState.employeeDataResponse.clinics
            });
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (nextProps.employeeDataState.employeeDataResponse && this.props.employeeDataState.employeeDataResponse
            && nextProps.employeeDataState.employeeDataResponse.clinics !== this.props.employeeDataState.employeeDataResponse.clinics) {
            toast.success(nextProps.employeeDataState.removeDefaultClinicResponse?.status.msg);
            this.setState({ clinic: nextProps.employeeDataState.employeeDataResponse.clinics });
        }
        return true;
    }
    render() {
        return (
            <>

                <section className="pantient-view">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-md-12 text-center">
                                <h4 className="page-title">My Clinics</h4>
                            </div>
                            {
                                this.state.clinic.map((value, index) => {
                                    let letterOne = '';
                                    let letterTwo = '';
                                    if (value.name && value.name !== "") {
                                        letterOne = value.name.split(" ")[0] ? value.name.split(" ")[0].charAt(0) : '';
                                        letterTwo = value.name.split(" ")[1] ? value.name.split(" ")[1].charAt(1) : '';
                                    }
                                    return (
                                        <div className="col-sm-8">
                                            <ul className="myclinic-list">
                                                <li className="mycli d-flex">
                                                    <a className="media flex-grow-1" href="javascript:void(0);">
                                                        {
                                                            (value.logo.name !== "" && value.logo.type !== "") ?
                                                                <div className="media-img mr-3">
                                                                    <img src={configs.IMAGE_URL + 'clinic/' + value.logo.name + '-200x200.' + value.logo.type} className="img-fluid" />
                                                                </div>
                                                                :
                                                                <Avatar>{letterOne}{letterTwo}</Avatar>
                                                        }
                                                        <div className="media-body align-self-center">
                                                            <div className="cl-name">{value.name}</div>
                                                            <div className="cl-loc">{showAddress(value.address)}</div>
                                                        </div>
                                                    </a>
                                                    {
                                                        (value.prescreen === null || value.prescreen !== true) &&
                                                        <div className="text-center">
                                                            <a href="javascript:void(0);" onClick={(e) => {
                                                                this.setState({
                                                                    showPrescreeningModal: true,
                                                                    selectedClinic: value
                                                                });
                                                            }}>
                                                                <img src={require('../../assets/images/covid-icon.svg')} className="" />
                                                            </a>
                                                        </div>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
                {
                    (this.state.showPrescreeningModal === true && this.state.selectedClinic !== undefined) &&
                    <PrescreeningModal isShow={this.state.showPrescreeningModal} prescreeningQuestions={this.props.employeeDataState.employeeDataResponse ?
                        this.props.employeeDataState.employeeDataResponse.prescreening : []} selectedClinic={this.state.selectedClinic}
                        closeModal={() => {
                            this.setState({ showPrescreeningModal: false, selectedClinic: undefined });
                        }}></PrescreeningModal>
                }
            </>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    employeeDataState: state.employeeData
});
const mapStateToDispatch = (dispatch: any) => ({
})
export default connect(mapStateToProps, mapStateToDispatch)(MyClinic);