export const TITLES_LABELS = {
    LOGIN: {
        PATIENT_LOGIN: 'Login',
        FORGOT_PASSWORD: 'Forgot Password',
        REGISTER: 'Register'
    },
    ACCOUNT: {
        SAVE_BUTTON: 'Save Changes',
        CANCEL_BUTTON: 'Cancel'
    },
    LINKS: {
        FORGOT_PASSWORD: 'Forgot password/Change password?',
        REGISTER: "Register"
    },
    META: {
        LOGIN: 'Login - xcarepro',
        REFER: 'Referrals - xcarepro',
        CLAIM: 'Claim Reward - xcarepro',
        APPOINTMENT: 'Appointments - xcarepro',
        ACCOUNT: 'Account - xcarepro'
    }
};