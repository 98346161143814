const DEV_HOST = ['employee.staging.xcarepro.com', 'localhost'];
const PROD_HOST = ['employee.xcarepro.com'];
const hostname = window.location.hostname;
let configs: any = {};
if (DEV_HOST.findIndex(item => { return item === hostname }) !== -1) {
    //Dev Mode
    const SOURCE = "https://staging.xcarepro.com/";
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'api/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        GOOGLE_API_KEY: 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8',
        STRIPE_PUBLIC_KEY: 'pk_test_lDXsJpSjBU246cZtxswWkRsx002OC42goy',
        COOKIE_DOMAIN: '.staging.xcarepro.com',
        PATIENT_URL: 'http://employee.staging.xcarepro.com/',
        FRONT_URL: 'http://staging.xcarepro.com/',
        CHAT_COLLECTION_NAME: 'chat' //chat for staging and chat_live for live.
    };
} else if (PROD_HOST.findIndex(item => { return item === hostname }) !== -1) {
    //Prod Mode
    const SOURCE = "https://www.xcarepro.com/";
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'api/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        GOOGLE_API_KEY: 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8',
        STRIPE_PUBLIC_KEY: 'pk_live_kiZnBAq9UhZ51XE48yE4PcN0008A3aLh2Z',
        COOKIE_DOMAIN: '.xcarepro.com',
        PATIENT_URL: 'https://employee.xcarepro.com/',
        FRONT_URL: 'https://www.xcarepro.com/',
        CHAT_COLLECTION_NAME: 'chat_live' //chat for staging and chat_live for live.
    };
}
export default configs;
