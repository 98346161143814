import { logoutUser } from './../utils/firebaseUtils';
import Cookie from 'js-cookie';
import { LoginRequest, LoginPendingAction, AUTH, RegisterRequest, RegisterPendingAction } from './../store/auth/types';
import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { loginSuccess, loginError, registerError, registerSuccess } from '../store/auth/actions';
import { fetchLoadingSuccess, fetchLoadingPending } from '../store/loadingIndicator/actions';
import apiConfigs from '../configs/apiConfigs';
function execute(request: LoginRequest) {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(CONFIG.API_URL + "employeelogin/", request, {
        headers: headers,
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* loginPendingRun(action: LoginPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(loginError(response.data));
            } else {
                yield put(loginSuccess(response.data));
            }
        } else {
            yield put(loginError(response));
        }
    }
    if (error) {
        yield put(loginError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* loginPendingWatcher() {
    yield takeEvery(AUTH.LOGIN_PENDING, loginPendingRun);
}

function loggedOut() {
    Cookie.remove('employee_cookie');
    Cookie.remove('employee_cookie', { domain: apiConfigs.COOKIE_DOMAIN });
    logoutUser();
    // localStorage.removeItem('login:Patient');
}

export function* logoutWatcher() {
    yield takeEvery(AUTH.LOGGED_OUT, loggedOut);
}

function executeRegister(request: RegisterRequest) {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(CONFIG.API_URL + "signup/", request, {
        headers: headers,
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* registerPendingRun(action: RegisterPendingAction) {
    const { response, error } = yield executeRegister(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(registerError(response.data));
            } else {
                yield put(registerSuccess(response.data));
            }
        } else {
            yield put(registerError(response));
        }
    }
    if (error) {
        yield put(registerError(error));
    }
}

export function* registerPendingWatcher() {
    yield takeEvery(AUTH.REGISTER, registerPendingRun);
}