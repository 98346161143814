import { LoginRequest, LoginPendingAction, AUTH } from '../store/auth/types';
import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import {
    EMPLOYEE_DATA, EmployeeDataRequest, EmployeeDataPendingAction,
    EmailVerificationRequest, EmailVerificationPendingAction, PrescreeningSubmitPendingAction, PrescreeningSubmitRequest
} from '../store/employeeData/types';
import { employeeDataError, employeeDataSuccess, emailVerificationErrorAction, emailVerificationSuccessAction, prescreeningSubmitErrorAction, prescreeningSubmitSuccessAction } from '../store/employeeData/actions';
import { attachHeader } from '../utils';
import { fetchLoadingPending, fetchLoadingSuccess } from '../store/loadingIndicator/actions';

function execute(request: EmployeeDataRequest) {
    /* const headers = {
        'Content-Type': 'application/json',
    }; */
    const body = {
        // id: request.userId,
        uniqueid: request.uniqueId
    }
    return axios.post(CONFIG.API_URL + "employeedata/", body, {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* employeeDataPendingRun(action: EmployeeDataPendingAction) {
    //    yield delay(2000);
    if (action.payload.doNotShowLoader === true) {
        //do nothing
    } else {
        yield put(fetchLoadingPending());
    }
    const { response, error } = yield execute(action.payload);
    console.log('Response', JSON.stringify(response));
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(employeeDataError(response.data));
            } else {
                yield put(employeeDataSuccess(response.data));
            }
        } else {
            yield put(employeeDataError(response));
        }
    }
    if (error) {
        yield put(employeeDataError(error));
    }
    if (action.payload.doNotShowLoader === true) {
        //do nothing
    } else {
        yield put(fetchLoadingSuccess(false));
    }
}

export function* employeeDataPendingWatcher() {
    yield takeEvery(EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_PENDING, employeeDataPendingRun);
}

function executeEmailVerification(request: EmailVerificationRequest) {
    const body = {
        email: request.email
    };
    return axios.post(CONFIG.API_URL + 'verify/' + request.modelType + "/" + request.modelUniqueid + "/", body, {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
export function* emailVerificationRun(action: EmailVerificationPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeEmailVerification(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(emailVerificationErrorAction(response.data));
            } else {
                yield put(emailVerificationSuccessAction(response.data));
            }
        } else {
            yield put(emailVerificationErrorAction(response));
        }
    }
    if (error) {
        yield put(emailVerificationErrorAction(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* emailVerificationPendingWatcher() {
    yield takeEvery(EMPLOYEE_DATA.EMAIL_VERIFICATION_PENDING, emailVerificationRun);
}
/* Prescreening Submit  */
function execurePrescreeningSubmit(request: PrescreeningSubmitRequest) {
    const body = {
        workid: request.workid,
        data: request.data
    };
    return axios.post(CONFIG.API_URL + 'employee/' + request.employeeUniqueId + "/prescreen/", body, {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* prescreeningSubmitRun(action: PrescreeningSubmitPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield execurePrescreeningSubmit(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(prescreeningSubmitErrorAction(response.data));
            } else {
                yield put(prescreeningSubmitSuccessAction(response.data));
            }
        } else {
            yield put(prescreeningSubmitErrorAction(response));
        }
    }
    if (error) {
        yield put(prescreeningSubmitErrorAction(error));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* prescreeningSubmitPendingWatcher() {
    yield takeEvery(EMPLOYEE_DATA.SUBMIT_PRESCREENING, prescreeningSubmitRun);
}