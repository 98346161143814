import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Clinic } from '../../store/employeeData/types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { prescreeningSubmitPendingAction, employeeDataPending } from '../../store/employeeData/actions';
import { IAppState } from '../../store';
const PrescreeningModal = (props: {
    isShow: boolean;
    prescreeningQuestions: any[];
    selectedClinic: Clinic | undefined;
    closeModal: () => void;
}) => {
    const [state, setState] = useState({
        currentQuestion: 0,
        prescreeningQuestions: props.prescreeningQuestions,
        allNoCheckBox: false
    });
    const dispatch = useDispatch();
    const employeeData = useSelector((state: any) => state.employeeData.employeeDataResponse, shallowEqual);
    const prescreeningResponse = useSelector((state: any) => state.employeeData.prescreeningSubmitResponse, shallowEqual);
    const error = useSelector((state: any) => state.employeeData.error, shallowEqual);
    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);
    useEffect(() => {
        if (prescreeningResponse && prescreeningResponse.status && prescreeningResponse.status.error === false &&
            prescreeningResponse.status.msg &&
            prescreeningResponse.status.msg !== '') {
            toast.success(prescreeningResponse.status.msg);
            dispatch(employeeDataPending({ uniqueId: employeeData.uniqueId }));
            props.closeModal();
        }
    }, [prescreeningResponse]);
    const yesNoClickPreScreening = (questionIndex: number, answer: any) => {
        if (questionIndex === state.prescreeningQuestions.length - 1 && answer === "yes") {
            setState({
                ...state,
                prescreeningQuestions: state.prescreeningQuestions.map((ques: any, index: number) => {
                    if (index === questionIndex) {
                        return {
                            ...ques,
                            answer: answer,
                            oldAnswer: answer,
                        }
                    }
                    return ques;
                })
            });
            return;
        }
        setState({
            ...state,
            allNoCheckBox: false,
            prescreeningQuestions: state.prescreeningQuestions.map((ques: any, index: number) => {
                if (index === questionIndex) {
                    return {
                        ...ques,
                        answer: answer,
                        oldAnswer: answer,
                    }
                }
                return ques;
            })
        });
    }
    const addRemoveOptions = (questionIndex: number, ques: any) => {
        setState({
            ...state,
            prescreeningQuestions: state.prescreeningQuestions.map((value: any, index: number) => {
                if (questionIndex === index) {
                    const flag = state.prescreeningQuestions[questionIndex].answer.findIndex((option: any, oIndex: number) => {
                        return ques === option;
                    });
                    if (flag !== -1) {
                        return {
                            ...value,
                            answer: value.answer.filter((qValue: any) => { return (qValue !== ques) })
                        }
                    } else if (ques.toLowerCase() === "none") {
                        return {
                            ...value,
                            answer: [ques]
                        }
                    }
                    return {
                        ...value,
                        answer: [...value.answer, ques].filter((qValue: any) => { return qValue.toLowerCase() !== "none" })
                    }
                }
                return value;
            })
        });
    }
    const onFinishClick = () => {
        let allowed = true;
        for (let start = 0; start < state.prescreeningQuestions.length; start++) {
            const obj = state.prescreeningQuestions[start];
            if (obj.options && obj.answer.length === 0 && start !== state.prescreeningQuestions.length - 1) {
                allowed = false;
                toast.warn('Please answer all questions.');
                return;
            } else if ((obj.answer === undefined || obj.answer === null || obj.answer === '') && start !== state.prescreeningQuestions.length - 1) {
                allowed = false;
                toast.warn('Please answer all questions.');
                return;
            } else if (start === state.prescreeningQuestions.length - 1 && obj.answer === "no") {
                allowed = false;
                toast.warn("Please verify that your answeres are correct.");
                return;
            }
        }
        if (allowed) {
            const prescreeningQuestions = state.prescreeningQuestions.map((value: any) => {
                if (value.options) {
                    return {
                        ...value,
                        answer: value.answer.toString()
                    }
                }
                return value;
            });
            if (props.selectedClinic && employeeData && employeeData.uniqueId) {
                dispatch(prescreeningSubmitPendingAction({
                    employeeUniqueId: employeeData.uniqueId, data: prescreeningQuestions, workid: props.selectedClinic.workid
                }));
            }
        }
    }
    return (
        <>
            <Modal centered className="model_covid" show={props.isShow} onHide={() => {
                props.closeModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Prescreening Questions</Modal.Title>
                </Modal.Header>
                <ModalBody>

                    {
                        (state.prescreeningQuestions && state.prescreeningQuestions.length > 0) &&
                        state.prescreeningQuestions.map((question: any, index: number) => {
                            if (question.options) {
                                return (
                                    <div className="covdque" key={index + Math.random()}>
                                        {index + 1}. {state.prescreeningQuestions[index].question}
                                        <div className="deslist">
                                            <ul className="list-group">
                                                {
                                                    question.options.map((ques: any, qIndex: number) => {
                                                        const findOption = question.answer.findIndex((option: any, oIndex: number) => {
                                                            return ques === option;
                                                        });
                                                        return (
                                                            <li className="list-group-item" key={qIndex + Math.random()}>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" id={ques} name={ques} className="custom-control-input"
                                                                        value={ques}
                                                                        checked={findOption !== -1 ? true : false}
                                                                        onChange={(e) => {
                                                                            addRemoveOptions(index, e.target.value);
                                                                        }}
                                                                    />
                                                                    <label title="" htmlFor={ques} className="custom-control-label">{ques}</label>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )
                            }
                            if (state.prescreeningQuestions.length - 1 === index) {
                                return (
                                    <>
                                        <div>
                                            <input type="checkbox" id="allNoCheckBox" name="allNoCheckBox" checked={state.allNoCheckBox} value={state.allNoCheckBox ? "yes" : "no"} onChange={(e) => {
                                                setState({
                                                    ...state,
                                                    allNoCheckBox: !state.allNoCheckBox,
                                                    prescreeningQuestions: state.prescreeningQuestions.map((ques: any, index: number) => {
                                                        if (state.prescreeningQuestions.length - 1 === index) {
                                                            return {
                                                                ...ques,
                                                                answer: state.allNoCheckBox === true ? "no" : "no",
                                                            }
                                                        }
                                                        return {
                                                            ...ques,
                                                            answer: state.allNoCheckBox === false ? "no" : ques.oldAnswer,
                                                        }
                                                    })
                                                });
                                            }}></input>
                                            <label htmlFor="allNoCheckBox"> &nbsp; Please check to select all as NO.</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" id="confirmCheckbox" name="confirmCheckbox" checked={question.answer === "yes" ? true : false}
                                                value={question.answer} onChange={(e) => {
                                                    yesNoClickPreScreening(index, question.answer === "yes" ? "no" : "yes");
                                                }}></input>
                                            <label htmlFor="confirmCheckbox"> &nbsp; {question.question}</label>
                                        </div>
                                    </>
                                )
                            }
                            return (
                                <div key={index + Math.random()}>
                                    <div className="covdque">
                                        {index + 1}. {question.question}
                                    </div>
                                    <div className="ysnrad">
                                        <div className="form-check-inline yes-no">
                                            <input type="radio" name={"has_received" + index} id={"received_yes" + index} value={question.answer}
                                                checked={question.answer === 'yes'}
                                                onChange={(e) => {
                                                    yesNoClickPreScreening(index, 'yes');
                                                }}
                                            />
                                            <label className="l-yes" htmlFor={"received_yes" + index}><img src={require('../../assets/images/yes-icon.svg')} /></label>
                                            <span>Yes</span>
                                        </div>
                                        <div className="form-check-inline yes-no">
                                            <input type="radio" name={"has_received" + index} id={"received_no" + index} value={question.answer}
                                                checked={question.answer === 'no'}
                                                onChange={(e) => {
                                                    yesNoClickPreScreening(index, 'no');
                                                }} />
                                            <label className="l-no" htmlFor={"received_no" + index}><img src={require('../../assets/images/no-icon.svg')} /></label>
                                            <span>No</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* <div>
                        <input type="checkbox" id="allNoCheckBox" name="allNoCheckBox" checked={state.allNoCheckBox} value={state.allNoCheckBox ? "yes" : "no"} onChange={(e) => {
                            setState({
                                ...state,
                                allNoCheckBox: !state.allNoCheckBox,
                                prescreeningQuestions: state.prescreeningQuestions.map((ques: any, index: number) => {
                                    if (index === state.prescreeningQuestions.length - 1) {
                                        return {
                                            ...ques,
                                            answer: state.allNoCheckBox === false ? "yes" : ques.oldAnswer,
                                        }
                                    }
                                    return {
                                        ...ques,
                                        answer: state.allNoCheckBox === false ? "no" : ques.oldAnswer,
                                    }
                                })
                            });
                        }}></input>
                        <label htmlFor="allNoCheckBox"> &nbsp; Please check to select all as NO.</label>
                    </div> */}
                    <div className="andpbtn">
                        <button type="button" className="btn btn-cancel btn-custom"
                            onClick={(e) => {
                                onFinishClick();
                            }}>Finish</button>
                    </div>
                    {
                        !(state.prescreeningQuestions && state.prescreeningQuestions.length > 0) &&
                        <label className="text text-danger">No questions avaiable</label>
                    }
                </ModalBody>
            </Modal>
            {/* <Modal centered className="model_covid" show={props.isShow} onHide={() => {
            props.closeModal();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{state.currentQuestion + 1}/{Object.keys(state.prescreeningQuestions).length}</Modal.Title>
            </Modal.Header>
            <ModalBody>

                {
                    (state.prescreeningQuestions && state.prescreeningQuestions.length > 0) &&
                    <>
                        {

                            (state.prescreeningQuestions[state.currentQuestion].options) ?
                                <>
                                    <div className="covdque">
                                        {state.prescreeningQuestions[state.currentQuestion].question}
                                        <div className="deslist">
                                            <ul className="list-group">
                                                {
                                                    state.prescreeningQuestions[state.currentQuestion].options.map((ques: any, qIndex: number) => {
                                                        const findOption = state.prescreeningQuestions[state.currentQuestion].answer.findIndex((option: any, oIndex: number) => {
                                                            return ques === option;
                                                        });
                                                        return (
                                                            <li className="list-group-item" key={qIndex + Math.random()}>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" id={ques} name={ques} className="custom-control-input"
                                                                        value={ques}
                                                                        checked={findOption !== -1 ? true : false}
                                                                        onChange={(e) => {
                                                                            addRemoveOptions(state.currentQuestion, e.target.value);
                                                                        }}
                                                                    />
                                                                    <label title="" htmlFor={ques} className="custom-control-label">{ques}</label>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </>
                                :

                                <>
                                    <div className="covdque">
                                        {state.prescreeningQuestions[state.currentQuestion].question}
                                    </div>
                                    <div className="ysnrad">
                                        <div className="form-check-inline yes-no">
                                            <input type="radio" name="has_received" id="received_yes" value={state.prescreeningQuestions[state.currentQuestion].answer}
                                                checked={state.prescreeningQuestions[state.currentQuestion].answer === 'yes'}
                                                onChange={(e) => {
                                                    yesNoClickPreScreening(state.currentQuestion, 'yes');
                                                }}
                                            />
                                            <label className="l-yes" htmlFor="received_yes"><img src={require('../../assets/images/yes-icon.svg')} /></label>
                                            <span>Yes</span>
                                        </div>
                                        <div className="form-check-inline yes-no">
                                            <input type="radio" name="has_received" id="received_no" value={state.prescreeningQuestions[state.currentQuestion].answer}
                                                checked={state.prescreeningQuestions[state.currentQuestion].answer === 'no'}
                                                onChange={(e) => {
                                                    yesNoClickPreScreening(state.currentQuestion, 'no');
                                                }} />
                                            <label className="l-no" htmlFor="received_no"><img src={require('../../assets/images/no-icon.svg')} /></label>
                                            <span>No</span>
                                        </div>
                                    </div>
                                </>

                        }
                    </>
                }
                <div className="andpbtn">
                    {
                        (state.prescreeningQuestions && (state.currentQuestion > 0)) &&
                        <button type="button" className="btn btn-save btn-custom mr-3" onClick={(e) => {
                            setState({ ...state, currentQuestion: state.currentQuestion - 1 });
                        }}>Back</button>
                    }
                    {
                        (state.prescreeningQuestions && (state.currentQuestion === state.prescreeningQuestions.length - 1)) ?
                            <button type="button" className="btn btn-cancel btn-custom" disabled={!(state.prescreeningQuestions &&
                                state.prescreeningQuestions[state.currentQuestion].answer && state.prescreeningQuestions[state.currentQuestion].answer.length !== 0)}
                                onClick={(e) => {
                                    onFinishClick();
                                }}>Finish</button>
                            :
                            <button type="button" className="btn btn-cancel btn-custom" disabled={!(state.prescreeningQuestions && state.prescreeningQuestions[state.currentQuestion].answer &&
                                state.prescreeningQuestions[state.currentQuestion].answer.length !== 0)}
                                onClick={(e) => {
                                    setState({ ...state, currentQuestion: state.currentQuestion + 1 });
                                }}>Next</button>
                    }

                </div>
            </ModalBody>
        </Modal> */}
        </>
    )
};
export default PrescreeningModal;