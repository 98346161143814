import React from 'react';
import { isLogin, getCookie } from '../../utils';
import { Redirect, NavLink, Switch, Route } from 'react-router-dom';
import Account from '../../components/account/Account';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { userLogout, loginSuccess } from '../../store/auth/actions';
import { AuthState, LoginResponse } from '../../store/auth/types';
import { EmployeeDataRequest, EmployeeDataState } from '../../store/employeeData/types';
import { employeeDataPending } from '../../store/employeeData/actions';
import { LoadingState } from '../../store/loadingIndicator/types';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import Cookie from 'js-cookie';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../assets/css/style.scss'
import MyClinic from '../../components/myClinic/MyClinic';
import { Avatar } from '@material-ui/core';
import { getCurrentUser, signInWithCustomToken } from '../../utils/firebaseUtils';
import $ from 'jquery';
interface Props {
    location: any;
    logout: typeof userLogout;
    authState: AuthState;
    login: typeof loginSuccess;
    employeeDataState: EmployeeDataState;
    employeeData: typeof employeeDataPending;
    loadingState: LoadingState;
}
interface State {
    validLogin: boolean;
    isDataLoaded: boolean;
    isLoading: boolean;
}
class MainContainer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            validLogin: true,
            isDataLoaded: false,
            isLoading: false,
        }
    }
    componentDidMount() {
        if (Cookie.getJSON('employee_cookie') === undefined || Cookie.getJSON('employee_cookie') === null) {
            toast.warn('Please check cookies are enabled or not, we require cookie to use the website features.');
            this.setState({ validLogin: false });
        } else if (isLogin()) {
            const login: any = getCookie();
            if (!getCurrentUser()) {
                try {
                    /* signInWithCustomToken(getCookie().jwtToken).then((signInSuccess) => { */
                    this.props.login(login);
                    // this.props.employeeData({ userId: login.id, uniqueId: login.uniqueId });
                    this.props.employeeData({ uniqueId: login.xpr_user_id });
                    this.setState({ validLogin: true });
                    /* }).catch((error) => {
                        this.props.logout();
                        this.setState({ validLogin: false });
                    }) */
                } catch (err) {
                    this.props.logout();
                    this.setState({ validLogin: false });
                }
            } else {
                this.props.login(login);
                // this.props.employeeData({ userId: login.id, uniqueId: login.uniqueId });
                this.props.employeeData({ uniqueId: login.xpr_user_id });
                this.setState({ validLogin: true });
            }
        } else {
            this.setState({ validLogin: false });
        }
        // responsive menu
        $('a.target-burger').click(function (e) {
            $('nav.mobile-nav, a.target-burger, body').toggleClass('toggled');
            e.preventDefault();
            $('.mobile-nav .menu li:has(ul)').append('<span class="dsarrow"></span>');

            $('.mobile-nav .sub-menu').slideUp();
            $(".mobile-nav .menu li:has(ul)").find(".dsarrow").click(function () {
                // if(false == $(this).parent().children(".mobile-nav .menu li ul.sub-menu").is(':visible')) {
                //     // $('.mobile-nav .menu li ul.sub-menu').slideUp();
                // }
                $(this).parent().children(".mobile-nav .menu li ul.sub-menu").slideToggle();
            });
        });
        $('.mobile-nav .navbar-nav .nav-item .nav-link').click(function () {
            $("nav.mobile-nav").removeClass("toggled");
            $("a.target-burger").removeClass("toggled");
        })

    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (nextProps !== this.props) {
            if (nextProps.loadingState.pending !== this.props.loadingState.pending) {
                if (nextProps.loadingState.pending === true) {
                    this.setState({ isLoading: true });
                } else {
                    this.setState({ isLoading: false });
                }
            }
            if (nextProps.employeeDataState.error !== null) {
                toast.error('Something went wrong.');
            } else {
                if (nextProps.employeeDataState.pending === false) {
                    if (nextProps.employeeDataState.employeeDataResponse && nextProps.employeeDataState.employeeDataResponse.status
                        && nextProps.employeeDataState.employeeDataResponse.status.error === false) {
                        this.setState({ isDataLoaded: true });
                    }
                }
            }
        }
        return true;
    }
    componentDidUpdate() {
        this.props.employeeDataState.error = null;
        if (this.props.employeeDataState.employeeDataResponse) {
            this.props.employeeDataState.employeeDataResponse.status.error = true;
        }
    }
    userLogoutClick() {
        this.props.logout();
        this.setState({ validLogin: false });
    }
    render() {
        console.log(this.props.location);
        if (this.state.validLogin === false) {
            return (
                <div className="app">
                    <Redirect to="/login"></Redirect>
                </div>
            );
        } else {
            let letterOne = '';
            let letterTwo = '';
            if (this.props.employeeDataState && this.props.employeeDataState.employeeDataResponse &&
                this.props.employeeDataState.employeeDataResponse.firstName &&
                this.props.employeeDataState.employeeDataResponse.firstName !== "" &&
                this.props.employeeDataState.employeeDataResponse.lastName &&
                this.props.employeeDataState.employeeDataResponse.lastName !== "") {
                letterOne = this.props.employeeDataState.employeeDataResponse.firstName.charAt(0).toUpperCase();
                letterTwo = this.props.employeeDataState.employeeDataResponse.lastName.charAt(0).toUpperCase();
            }
            return (
                <div id="root">
                    {
                        this.state.isLoading === true &&
                        <LoadingIndicator></LoadingIndicator>
                    }
                    <header className="master-header screen patient">
                        <div className="top-sec">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <NavLink className="nav-brand" to="/">
                                            <img src={require('../../assets/images/logo-black.svg')} />
                                        </NavLink>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-end user-cred-sec">

                                        {/* <div className="hd_loot d-flex">
                                            <div className="lbimg"><img src={require('../../assets/images/lootbox-sm.svg')} /></div>
                                            <div className="lb_vnt">Reward Point: <span>Coming soon</span></div>
                                        </div> */}

                                        <div className="user-operations">
                                            <div className="user-name d-inline-block mr-3">{(this.props.employeeDataState && this.props.employeeDataState.employeeDataResponse) ? (this.props.employeeDataState.employeeDataResponse.firstName + " " + this.props.employeeDataState.employeeDataResponse.lastName) : ''}</div>
                                            <div className="user-img d-inline-block mr-3">
                                                <NavLink to="/account">
                                                    <Avatar>{letterOne}{letterTwo}</Avatar>
                                                    {/* <img src={require('../../assets/images/user-placeholder.svg')} /> */}
                                                </NavLink>
                                            </div>
                                            <NavLink to="" className="logout-btn d-inline-block" onClick={(e) => { this.userLogoutClick() }}><img src={require('../../assets/images/logout-icon.svg')} /></NavLink>
                                            <a className="target-burger d-inline-block">
                                                <ul className="buns">
                                                    <li className="bun"></li>
                                                    <li className="bun"></li>
                                                </ul>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-sec">
                            <div className="container">
                                <nav className="navbar navbar-expand-sm main-header-menu pt-0 pb-0 desk-menu">
                                    <ul className="navbar-nav mx-auto">
                                        <li className={"nav-item " + ((this.props.location.pathname === "/my-clinics") ? 'active' : '')}>
                                            <NavLink to="/my-clinics" className="nav-link">
                                                <div className="nav_ic">
                                                    <img src={require('../../assets/images/my-clinic-ic.svg')} className="menu-icons mb-2 mx-auto d-block" />
                                                </div>
                                                My Clinics
                                            </NavLink>
                                        </li>
                                        <li className={"nav-item " + (this.props.location.pathname === "/account" ? 'active' : '')}>
                                            <NavLink to={{
                                                pathname: "/account",
                                            }} className="nav-link">
                                                <div className="nav_ic">
                                                    <img src={require('../../assets/images/menu-account.svg')} className="menu-icons mb-2 mx-auto d-block" />
                                                </div>
                                                Account
                                                </NavLink>
                                        </li>

                                    </ul>
                                </nav>

                                <div className="mobile-navigation">

                                    <nav className="navbar navbar-expand-sm main-header-menu pt-0 pb-0 mobile-nav">
                                        <ul className="navbar-nav mx-auto">
                                            <li className={"nav-item " + ((this.props.location.pathname === "/my-clinics") ? 'active' : '')}>
                                                <NavLink to="/my-clinics" className="nav-link">
                                                    <div className="nav_ic">
                                                        <img src={require('../../assets/images/my-clinic-ic.svg')} className="menu-icons mb-2 mx-auto d-block" />
                                                    </div>
                                                My Clinics
                                            </NavLink>
                                            </li>
                                            <li className={"nav-item " + (this.props.location.pathname === "/account" ? 'active' : '')}>
                                                <NavLink to={{
                                                    pathname: "/account",
                                                }} className="nav-link">
                                                    <div className="nav_ic">
                                                        <img src={require('../../assets/images/menu-account.svg')} className="menu-icons mb-2 mx-auto d-block" />
                                                    </div>
                                                Account
                                                </NavLink>
                                            </li>

                                        </ul>
                                    </nav>

                                </div>

                            </div>
                        </div>
                    </header>
                    {
                        this.state.isDataLoaded === true &&
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/my-clinics"></Redirect>
                            </Route>
                            <Route exact path="/account" component={Account}></Route>
                            <Route exact path="/my-clinics" component={MyClinic}></Route>
                        </Switch>
                    }
                </div>
            )
        }
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    login: (request: LoginResponse) => {
        dispatch(loginSuccess(request));
    },
    logout: () => {
        dispatch(userLogout())
    },
    employeeData: (request: EmployeeDataRequest) => {
        dispatch(employeeDataPending(request));
    }
});
const mapStateToProps = (state: IAppState) => ({
    authState: state.auth,
    employeeDataState: state.employeeData,
    loadingState: state.loading,
});
export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);