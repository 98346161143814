import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginContainer from './containers/LoginContainer/LoginContainer';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainContainer from './containers/MainContainer/MainContainer';
import { Provider } from 'react-redux';
import configureStore from './store';
import './configs/firebaseConfigs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
toast.configure({ closeButton: false, closeOnClick: true, className: 'toaster', autoClose: 1500 });
const store = configureStore();
const Routing = (
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
                <Switch>
                    {/* <Route exact path="/" component={App}></Route> */}
                    <Route exact path="/login" render={(props) => <ErrorBoundary> <LoginContainer {...props}></LoginContainer> </ErrorBoundary>}></Route>
                    <Route path="/" render={(props: any) => <ErrorBoundary><MainContainer {...props}></MainContainer> </ErrorBoundary>}></Route>
                </Switch>
            </Router>
        </MuiPickersUtilsProvider>
    </Provider>
);
ReactDOM.render(Routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
