import {
    EmployeeDataRequest, EmployeeDataPendingAction, EMPLOYEE_DATA, EmployeeDataResponse,
    EmployeeDataSuccessAction, EmployeeDataErrorAction, ACCOUNT, AccountUpdatePendingAction, AccountUpdateRequest,
    AccountUpdateResponse, AccountUpdateSuccessAction,
    AccountUpdateErrorAction, EmailVerificationRequest,
    EmailVerificationPendingAction, EmailVerificationResponse, EmailVerificationSuccessAction, EmailVerificationErrorAction, PrescreeningSubmitRequest, PrescreeningSubmitPendingAction, PrescreeningSubmitErrorAction, PrescreeningSubmitResponse, PrescreeningSubmitSuccessAction,
} from './types';
/* Action Creators */
export const employeeDataPending = (request: EmployeeDataRequest): EmployeeDataPendingAction => {
    return {
        type: EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_PENDING,
        payload: request
    };
};

export const employeeDataSuccess = (response: EmployeeDataResponse): EmployeeDataSuccessAction => {
    return {
        type: EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_SUCCESS,
        employeeData: response
    }
}

export const employeeDataError = (err: any): EmployeeDataErrorAction => {
    return {
        type: EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_ERROR,
        error: err
    }
}

export const accountUpdatePending = (request: AccountUpdateRequest): AccountUpdatePendingAction => {
    return {
        type: ACCOUNT.ACCOUNT_UPDATE_PENDING,
        payload: request
    };
};

export const accountUpdateSuccess = (response: AccountUpdateResponse): AccountUpdateSuccessAction => {
    return {
        type: ACCOUNT.ACCOUNT_UPDATE_SUCCESS,
        accountUpdate: response
    }
}

export const accountUpdateError = (err: any): AccountUpdateErrorAction => {
    return {
        type: ACCOUNT.ACCOUNT_UPDATE_ERROR,
        error: err
    }
}

export const emailVerificationPendingAction = (request: EmailVerificationRequest): EmailVerificationPendingAction => {
    return {
        type: EMPLOYEE_DATA.EMAIL_VERIFICATION_PENDING,
        payload: request
    }
}
export const emailVerificationSuccessAction = (response: EmailVerificationResponse): EmailVerificationSuccessAction => {
    return {
        type: EMPLOYEE_DATA.EMAIL_VERIFICATION_SUCCESS,
        emailVerification: response
    }
}
export const emailVerificationErrorAction = (error: any): EmailVerificationErrorAction => {
    return {
        type: EMPLOYEE_DATA.EMAIL_VERIFICATION_ERROR,
        error: error
    }
}
export const prescreeningSubmitPendingAction = (request: PrescreeningSubmitRequest): PrescreeningSubmitPendingAction => ({
    type: EMPLOYEE_DATA.SUBMIT_PRESCREENING,
    payload: request
});
export const prescreeningSubmitErrorAction = (error: any): PrescreeningSubmitErrorAction => ({
    type: EMPLOYEE_DATA.SUBMIT_PRESCREENING_ERROR,
    payload: error
});
export const prescreeningSubmitSuccessAction = (response: PrescreeningSubmitResponse): PrescreeningSubmitSuccessAction => ({
    type: EMPLOYEE_DATA.SUBMIT_PRESCREENING_SUCCESS,
    payload: response
});