import { EmployeeDataState, EmployeeDataActionTypes, EMPLOYEE_DATA, ACCOUNT } from './types';

export const initialState: EmployeeDataState = {
    pending: false,
    error: null
};

export const employeeDataReducer = (state = initialState, action: EmployeeDataActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_PENDING:
            return {
                ...state,
                pending: true,
                employeeDataRequest: action.payload,
                prescreeningSubmitResponse: undefined,
                prescreeningSubmitRequest: undefined,
            }
        case EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                employeeDataResponse: action.employeeData
            }
        case ACCOUNT.ACCOUNT_UPDATE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case ACCOUNT.ACCOUNT_UPDATE_PENDING:
            return {
                ...state,
                pending: true,
                accountUpdateRequest: action.payload
            }
        case ACCOUNT.ACCOUNT_UPDATE_SUCCESS:
            if (action.accountUpdate.updateType === 'name') {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    employeeDataResponse: {
                        ...state.employeeDataResponse,
                        firstName: action.accountUpdate.firstName,
                        lastName: action.accountUpdate.lastName
                    }
                }
            } else if (action.accountUpdate.updateType === 'phone') {
                const phoneVerify = state.employeeDataResponse ? action.accountUpdate.phone === state.employeeDataResponse.phone ? state.employeeDataResponse.phoneVerified : null : null;
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    employeeDataResponse: {
                        ...state.employeeDataResponse,
                        phoneVerified: phoneVerify,
                        phone: action.accountUpdate.phone
                    }
                }
            } else if (action.accountUpdate.updateType === 'email') {
                const emailVerify = state.employeeDataResponse ? action.accountUpdate.email === state.employeeDataResponse.email ? state.employeeDataResponse.emailVerified : null : null;
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    employeeDataResponse: {
                        ...state.employeeDataResponse,
                        emailVerified: emailVerify,
                        email: action.accountUpdate.email
                    }
                }
            } else if (action.accountUpdate.updateType === 'dob') {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    employeeDataResponse: {
                        ...state.employeeDataResponse,
                        dob: action.accountUpdate.dob
                    }
                }
            } else if (action.accountUpdate.updateType === 'gender') {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    employeeDataResponse: {
                        ...state.employeeDataResponse,
                        gender: action.accountUpdate.gender
                    }
                }
            } else if (action.accountUpdate.updateType === 'address' && action.accountUpdate.address) {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate,
                    employeeDataResponse: {
                        ...state.employeeDataResponse,
                        addressline1: action.accountUpdate.address.addressline1,
                        addressline2: action.accountUpdate.address.addressline2,
                        country: action.accountUpdate.address.country,
                        state: action.accountUpdate.address.state,
                        city: action.accountUpdate.address.city,
                        postalCode: action.accountUpdate.address.postalCode
                    }
                }
            } else {
                return {
                    ...state,
                    pending: false,
                    accountUpdateResponse: action.accountUpdate
                }
            }
        case EMPLOYEE_DATA.EMAIL_VERIFICATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case EMPLOYEE_DATA.EMAIL_VERIFICATION_PENDING:
            return {
                ...state,
                pending: true,
                emailVerificationRequest: action.payload
            }
        case EMPLOYEE_DATA.EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                pending: false,
                emailVerificationResponse: action.emailVerification
            }
        case EMPLOYEE_DATA.SUBMIT_PRESCREENING:
            return {
                ...state,
                pending: true,
                prescreeningSubmitRequest: action.payload
            }
        case EMPLOYEE_DATA.SUBMIT_PRESCREENING_ERROR:
            return {
                ...state,
                pending: false,
                prescreeningSubmitRequest: undefined,
                error: action.payload
            }
        case EMPLOYEE_DATA.SUBMIT_PRESCREENING_SUCCESS:
            return {
                ...state,
                pending: false,
                prescreeningSubmitResponse: action.payload
            }
        default:
            return NewState;

    }
};