import { employeeDataReducer } from './employeeData/reducers';
import { authReducer } from './auth/reducers';
import { combineReducers, Store, createStore, applyMiddleware } from 'redux';
import CreateSagaMiddleware from "redux-saga";
import rootSaga from '../saga';
import { createLogger } from 'redux-logger';
import { loadingReducer } from './loadingIndicator/reducers';
import { forgotPasswordReducer } from './forgotpassword/reducers';
const appReducer = combineReducers({
    auth: authReducer,
    forgotPassword: forgotPasswordReducer,
    employeeData: employeeDataReducer,
    loading: loadingReducer,
});
const rootReducer = (state: any, action: any) => {
    if (action.type === 'USER_LOGGED_OUT') {
        state = undefined;
    }
    return appReducer(state, action);
}
const loggerMiddleware = createLogger();
export type IAppState = ReturnType<typeof rootReducer>;
export default function configureStore(): Store<any, any> {
    const sagaMiddleware = CreateSagaMiddleware();
    const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, loggerMiddleware));
    sagaMiddleware.run(rootSaga);
    return store;
}