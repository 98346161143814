import { all } from 'redux-saga/effects';
import loginPendingWatcher, { logoutWatcher, registerPendingWatcher } from './auth';
import { employeeDataPendingWatcher, emailVerificationPendingWatcher, prescreeningSubmitPendingWatcher } from './employeeData';
import { accountUpdatePendingWatcher } from './account';
import forgotPasswordPendingWatcher from './forgotPassword';
export default function* rootSaga() {
    yield all([
        loginPendingWatcher(), logoutWatcher(), employeeDataPendingWatcher(),
        accountUpdatePendingWatcher(), forgotPasswordPendingWatcher(),
        emailVerificationPendingWatcher(), prescreeningSubmitPendingWatcher()
    ]);
}