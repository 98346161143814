export enum AUTH {
    LOGIN_PENDING = 'LOGIN_PENDING',
    LOGIN_ERROR = 'LOGIN_ERROR',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGGED_OUT = 'USER_LOGGED_OUT',
    REGISTER = 'REGISTER',
    REGISTER_ERROR = 'REGISTER_ERROR',
    REGISTER_SUCCESS = 'REGISTER_SUCCESS',
};
export interface LoginRequest {
    email: string;
    password: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface LoginResponse {
    id: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: string;
    gender: string;
    dob: string;
    phone: string;
    phoneVerified: string;
    referralCode: string;
    addressline1: string;
    addressline2: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
    authParam: string;
    status: Status;
    jwtToken: string;
}
export interface RegisterRequest {
    fname: string;
    lname: string;
    email: string;
    phone: string;
    registeras: string;
    dob: string;
    password: string;
}
export interface RegisterResponse {
    id: string;
    status: { error: boolean, msg: string };
}
export interface AuthState {
    pending: boolean;
    loginRequest?: LoginRequest;
    loginResponse?: LoginResponse;
    registerResponse?: RegisterResponse;
    registerRequest?: RegisterRequest;
    error: any;
}

export interface LoginPendingAction {
    type: AUTH.LOGIN_PENDING;
    payload: LoginRequest;
}

export interface LoginErrorAction {
    type: AUTH.LOGIN_ERROR;
    error: any;
}
export interface LoginSuccessAction {
    type: AUTH.LOGIN_SUCCESS;
    login: LoginResponse;
}

export interface RegisterPendingAction {
    type: AUTH.REGISTER;
    payload: RegisterRequest;
}

export interface RegisterErrorAction {
    type: AUTH.REGISTER_ERROR;
    error: any;
}
export interface RegisterSuccessAction {
    type: AUTH.REGISTER_SUCCESS;
    register: RegisterResponse;
}
export type AuthActionTypes = LoginErrorAction | LoginPendingAction | LoginSuccessAction | RegisterSuccessAction |
    RegisterErrorAction | RegisterPendingAction;